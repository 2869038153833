import React from 'react';
import { Button, Stack } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useTranslation } from 'react-i18next';
import { FooterBar } from '../../../../components/layout/footer';

export interface NavigationFooterProps {
  onPrevious: () => unknown;
  onNext?: () => unknown | null | undefined;
}

function NavigationFooter({ onPrevious, onNext = undefined }: NavigationFooterProps) {
  const { t } = useTranslation();
  return (
    <FooterBar>
      <Stack direction="row" gap={2} alignItems="flex-start" sx={{ mr: 'auto' }}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={onPrevious}
          startIcon={
            <ArrowBackIcon />
          }
        >
          {t('common.previous')}
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          disabled={!onNext}
          onClick={onNext}
          endIcon={
            <ArrowForwardIcon />
          }
        >
          {t('common.next')}
        </Button>
      </Stack>
    </FooterBar>
  );
}

export default NavigationFooter;
