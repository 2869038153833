import React from 'react';
import {
  CardContent,
  CardProps,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { BeneficiariesCard } from './beneficiaries-panel.styles';
import useBusyState from '../../../../hooks/use-busy-state';
import BeneficiariesList from '../beneficiaries-list';
import { BeneficiaryDetails } from '../../../../services/models/beneficiary-details';

interface BeneficiariesPanelProps extends CardProps {
  beneficiaryDetails: BeneficiaryDetails
  onAddBeneficiary: () => unknown
}

function BeneficiariesPanel({
  beneficiaryDetails,
  onAddBeneficiary,
  ...props
}: BeneficiariesPanelProps) {
  const { t } = useTranslation();
  const [busy, withBusyState] = useBusyState();
  const hasBeneficiaries = beneficiaryDetails.nominations.length > 0;

  const handleAddBeneficiary = withBusyState(async () => {
    await onAddBeneficiary();
  });

  return (
    <BeneficiariesCard {...props}>
      <CardContent>
        <Stack gap={2} alignItems="flex-start">
          <Typography variant="h3">
            {t('components.beneficiariesPanel.beneficiaries')}
          </Typography>
          <Divider />
          {hasBeneficiaries ? (
            <BeneficiariesList beneficiaryDetails={beneficiaryDetails} />
          ) : (
            <Typography variant="body1" sx={(theme) => ({ color: theme.palette.common.greyLight, marginY: 1 })}>
              {t('components.beneficiariesPanel.noBeneficiariesAdded')}
            </Typography>
          )}
          <LoadingButton
            color="secondary"
            variant="contained"
            endIcon={!hasBeneficiaries ? <AddIcon /> : null}
            onClick={handleAddBeneficiary}
            loading={busy}
          >
            {hasBeneficiaries ? t('components.beneficiariesPanel.editBeneficiary') : t('components.beneficiariesPanel.addBeneficiary')}
          </LoadingButton>
        </Stack>
      </CardContent>
    </BeneficiariesCard>
  );
}

export default BeneficiariesPanel;
