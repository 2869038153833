import React, { ChangeEvent, useState } from 'react';
import _ from 'lodash';
import { useController } from 'react-hook-form';
import { InputBaseProps } from '@mui/material/InputBase';
import InputBase from './input-base';
import { WithOnChangeCommitted } from '../../types';
import { asStringOrNull } from '../../../utils/converters';

export const ZeroDecimalsFormatter = new Intl.NumberFormat('en-UK', {
  useGrouping: false,
  maximumFractionDigits: 0,
});

export const TwoDecimalsFormatter = new Intl.NumberFormat('en-UK', {
  useGrouping: false,
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const OneDecimalsFormatter = new Intl.NumberFormat('en-UK', {
  useGrouping: false,
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
});

export interface NumberInputProps extends Omit<InputBaseProps, 'name' | 'type'>, WithOnChangeCommitted<number | null> {
  name: string;
  formatter?: Intl.NumberFormat;
  min?: number;
  max?: number;
}

export function getRegExp(min?: number, max?: number, decimalPlaces?: number): RegExp {
  // eslint-disable-next-line no-nested-ternary
  const negativeSign = (min === undefined || min < 0)
    ? (max && max < 0 ? '-' : '-?')
    : '';
  const decimals = decimalPlaces ? `(\\.\\d{0,${decimalPlaces}})?` : '';
  const pattern = `^${negativeSign}\\d*${decimals}$`;
  return new RegExp(pattern);
}

function NumberInput({
  name,
  onChangeCommitted = undefined,
  formatter = ZeroDecimalsFormatter,
  min = undefined,
  max = undefined,
  inputProps,
  ...otherProps
}: NumberInputProps) {
  const [editedValue, setEditedValue] = useState<string | null>(null);
  const {
    field,
    formState: { defaultValues },
  } = useController({ name });
  const decimalPlaces = formatter.resolvedOptions().maximumFractionDigits;

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replaceAll(',', '');
    const regExp = getRegExp(min, max, decimalPlaces);
    if (value && !regExp.test(value)) {
      event.preventDefault();
      return;
    }
    const newValue = value ? parseFloat(value) : null;
    if (!newValue || !Number.isNaN(newValue)) {
      setEditedValue(event.target.value);
      field.onChange(newValue);
    }
  };

  const handleOnBlur = () => {
    const oldValue = _.get(defaultValues, name);
    const newValue = field.value;
    setEditedValue(null);
    field.onBlur();
    if (onChangeCommitted && asStringOrNull(oldValue) !== asStringOrNull(newValue)) {
      onChangeCommitted(name, newValue);
    }
  };

  const handleOnFocus = () => {
    const formattedValue = field.value ? formatter.format(field.value).replaceAll(',', '') : null;
    setEditedValue(formattedValue);
  };

  const formattedValue = editedValue ?? (field.value || field.value === 0 ? formatter.format(field.value) : '');
  return (
    <InputBase
      id={name}
      {...field}
      type="text"
      value={formattedValue}
      onChange={handleOnChange}
      onFocus={handleOnFocus}
      onBlur={handleOnBlur}
      fullWidth
      inputProps={{
        min,
        max,
        ...inputProps,
      }}
      {...otherProps}
    />
  );
}

export default NumberInput;
