import React, { useState } from 'react';
import {
  FormLabel,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormFieldContainer, InputStatus } from '../../../../components/form';
import { RadioOptionsField, TextField } from '../../../../components/form/fields';
import { AddressDto } from '../../../../services/models/address-dto';
import {
  firmDetailsSchema,
  FirmRegistrationDetails,
  RegistrationFormProps,
  SameCorrespondenceAddress,
} from '../../../../services/registration-helpers';
import NavigationFooter from '../navigation-footer';
import FirmAddressEntry from './firm-address';

type FormData = {
  firmName: string
  registeredAddress_address1: string
  registeredAddress_address2: string | undefined
  registeredAddress_cityName: string
  registeredAddress_county: string | undefined
  registeredAddress_postcode: string
  sameCorrespondenceAddress: string
  correspondenceAddress_address1: string | undefined
  correspondenceAddress_address2: string | undefined
  correspondenceAddress_cityName: string | undefined
  correspondenceAddress_county: string | undefined
  correspondenceAddress_postcode: string | undefined
};

function toFormData(firm: FirmRegistrationDetails | null) {
  return {
    firmName: firm?.firmName ?? '',
    registeredAddress_address1: firm?.registeredAddress?.address1 ?? '',
    registeredAddress_address2: firm?.registeredAddress?.address2 ?? '',
    registeredAddress_cityName: firm?.registeredAddress?.cityName ?? '',
    registeredAddress_county: firm?.registeredAddress?.county ?? '',
    registeredAddress_postcode: firm?.registeredAddress?.postcode ?? '',
    sameCorrespondenceAddress: undefined,
    correspondenceAddress_address1: firm?.correspondenceAddress?.address1 ?? '',
    correspondenceAddress_address2: firm?.correspondenceAddress?.address2 ?? '',
    correspondenceAddress_cityName: firm?.correspondenceAddress?.cityName ?? '',
    correspondenceAddress_county: firm?.correspondenceAddress?.county ?? '',
    correspondenceAddress_postcode: firm?.correspondenceAddress?.postcode ?? '',
  };
}

function toFirmRegistrationDetails(values: FormData): Partial<FirmRegistrationDetails> {
  const registeredAddress = {
    address1: values.registeredAddress_address1,
    address2: values.registeredAddress_address2,
    cityName: values.registeredAddress_cityName,
    postcode: values.registeredAddress_postcode,
  };

  return {
    firmName: values.firmName,
    registeredAddress,
    correspondenceAddress: values.sameCorrespondenceAddress === SameCorrespondenceAddress.SAME ? registeredAddress : {
      address1: values.correspondenceAddress_address1,
      address2: values.correspondenceAddress_address2,
      cityName: values.correspondenceAddress_cityName,
      postcode: values.correspondenceAddress_postcode,
    },
  };
}

function hasValidAddress(address: AddressDto): boolean {
  return ![address.address1, address.cityName, address.postcode].includes('');
}

function hasValidRegisteredAddress(values: FormData): boolean {
  return hasValidAddress({
    address1: values.registeredAddress_address1,
    cityName: values.registeredAddress_cityName,
    postcode: values.registeredAddress_postcode,
  });
}

function hasValidCorrespondenceAddress(values: FormData): boolean {
  if (values.sameCorrespondenceAddress === SameCorrespondenceAddress.SAME) {
    return hasValidRegisteredAddress(values);
  }
  return hasValidAddress({
    address1: values.correspondenceAddress_address1,
    cityName: values.correspondenceAddress_cityName,
    postcode: values.correspondenceAddress_postcode,
  });
}

export interface FirmDetailsFormProps extends RegistrationFormProps {}

function FirmDetailsForm({ registrationDetails, onPrevious, onNext }: FirmDetailsFormProps) {
  const [sameRegisteredAddress, setSameRegisteredAddress] = useState<boolean>(true);
  const { t } = useTranslation();

  const formMethods = useForm<FormData>({
    defaultValues: toFormData(registrationDetails),
    resolver: yupResolver(firmDetailsSchema),
    reValidateMode: 'onChange',
    mode: 'onChange',
  });

  const {
    formState: { isValid },
    setValue,
    getValues,
    trigger,
  } = formMethods;

  const handleSameAddressChange = (name: string, value: unknown) => {
    setSameRegisteredAddress(value === SameCorrespondenceAddress.SAME);
  };

  const handleAddressChange = (addressName: string, address: AddressDto) => {
    Object.keys(address).forEach((key) => {
      setValue(`${addressName}_${key}` as keyof FormData, address[key as keyof AddressDto] ?? '', { shouldDirty: true, shouldValidate: true });
    });
  };

  const handleNext = () => {
    if (onNext) {
      onNext(toFirmRegistrationDetails(getValues()));
    }
  };

  return (
    <FormProvider {...formMethods}>
      <Stack gap={2}>
        <Typography variant="h2">{t('components.firmRegistration.firmDetailsForm.title')}</Typography>
        <FormFieldContainer
          rowEnd={<InputStatus complete={getValues().firmName.length > 0} />}
          helpText={t('components.firmRegistration.firmDetailsForm.firmNameHelpText')}
        >
          <Stack direction="row" gap={3} alignItems="flex-start" className="MuiFormControl-root">
            <TextField
              sublabel={t('components.firmRegistration.firmDetailsForm.firmName')}
              name="firmName"
              autoComplete="off"
              onChangeCommitted={() => trigger('firmName')}
              FormControlProps={{
                sx: { maxWidth: 270 },
              }}
              hideError
            />
          </Stack>
        </FormFieldContainer>
        <FormFieldContainer
          rowEnd={<InputStatus complete={hasValidRegisteredAddress(getValues())} />}
          helpText={t('components.firmRegistration.firmDetailsForm.registeredAddressHelpText')}
        >
          <Stack sx={{ maxWidth: 750 }}>
            <FormLabel>{t('components.firmRegistration.firmDetailsForm.registeredAddress')}</FormLabel>
            <FirmAddressEntry addressName="registeredAddress" onAddressChange={handleAddressChange} />
          </Stack>
        </FormFieldContainer>
        <FormFieldContainer
          rowEnd={<InputStatus complete={hasValidCorrespondenceAddress(getValues())} />}
          helpText={t('components.firmRegistration.firmDetailsForm.correspondenceAddressHelpText')}
        >
          <Stack sx={{ maxWidth: 750 }}>
            <RadioOptionsField
              label={t('components.firmRegistration.firmDetailsForm.correspondenceAddress')}
              name="sameCorrespondenceAddress"
              options={Object.values(SameCorrespondenceAddress)}
              sx={{
                minWidth: 200,
              }}
              labelTranslationBasePath="components.firmRegistration.firmDetailsForm.correspondenceAddressOptions"
              onChangeCommitted={handleSameAddressChange}
              hideError
            />
            {sameRegisteredAddress === false && (
              <FirmAddressEntry addressName="correspondenceAddress" onAddressChange={handleAddressChange} />
            )}
          </Stack>
        </FormFieldContainer>
      </Stack>
      <NavigationFooter onPrevious={onPrevious} onNext={isValid ? handleNext : undefined} />
    </FormProvider>
  );
}

export default FirmDetailsForm;
