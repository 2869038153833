import React from 'react';
import {
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import { BeneficiariesReviewTable } from './beneficiaries-review.styles';
import { BeneficiaryDetails } from '../../../services/models/beneficiary-details';
import { toBeneficiaryFormData } from '../../../services/beneficiary-helpers';

interface BeneficiariesReviewProp {
  beneficiaryDetails: BeneficiaryDetails
}

function BeneficiariesReview({ beneficiaryDetails }: BeneficiariesReviewProp) {
  const outputBeneficiaries = toBeneficiaryFormData(beneficiaryDetails.nominations, beneficiaryDetails.beneficiaries);
  return (
    <BeneficiariesReviewTable size="small">
      <TableBody>
        {outputBeneficiaries.map((beneficiary) => (
          <TableRow key={beneficiary.rowId}>
            <TableCell>
              {`${beneficiary.firstName} ${beneficiary.surname}`}
            </TableCell>
            <TableCell>
              {beneficiary.relationship}
            </TableCell>
            <TableCell align="right">
              {`${beneficiary.percentage}%`}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </BeneficiariesReviewTable>
  );
}

export default BeneficiariesReview;
