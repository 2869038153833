import React from 'react';
import {
  Button,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormFieldContainer, InputStatus } from '../../../../components/form';
import { TextField } from '../../../../components/form/fields';
import { fcaReferenceSchema, FirmRegistrationDetails, RegistrationFormProps } from '../../../../services/registration-helpers';
import NavigationFooter from '../navigation-footer';

function toFormData(registrationDetails: FirmRegistrationDetails | null) {
  return {
    frn: registrationDetails?.frn ?? '',
  };
}

export interface FCAReferenceFormProps extends RegistrationFormProps {}

function FCAReferenceForm({ registrationDetails, onPrevious, onNext }: FCAReferenceFormProps) {
  const { t } = useTranslation();

  const formMethods = useForm({
    defaultValues: toFormData(registrationDetails),
    resolver: yupResolver(fcaReferenceSchema),
    reValidateMode: 'onChange',
    mode: 'onChange',
  });

  const {
    formState: { isValid },
    getValues,
  } = formMethods;

  const handleNext = () => {
    if (onNext) {
      onNext(getValues());
    }
  };

  return (
    <FormProvider {...formMethods}>
      <Stack gap={2}>
        <Typography variant="h2">{t('components.firmRegistration.fcaReferenceForm.title')}</Typography>
        <FormFieldContainer
          hideDivider
          rowEnd={<InputStatus complete={isValid} />}
          helpText={t('components.firmRegistration.fcaReferenceForm.frnHelpText')}
        >
          <Stack direction="row" gap={3} alignItems="flex-start" className="MuiFormControl-root">
            <TextField
              sublabel={t('components.firmRegistration.fcaReferenceForm.frnLabel')}
              name="frn"
              autoComplete="off"
              inputProps={{ maxLength: 7 }}
              FormControlProps={{
                sx: { maxWidth: 270 },
              }}
              hideError
            />
            <Button
              color="primary"
              variant="contained"
              size="large"
              sx={{ mt: 6.5 }}
              disabled={!isValid}
              onClick={handleNext}
            >
              {t('common.submit')}
            </Button>
          </Stack>
        </FormFieldContainer>
        <Link href="https://register.fca.org.uk/s/" variant="body2" color="inherit" target="_blank" rel="noopener noreferrer">
          {t('components.firmRegistration.fcaReferenceForm.frnSearchLink')}
        </Link>
      </Stack>
      <NavigationFooter onPrevious={onPrevious} onNext={isValid ? handleNext : undefined} />
    </FormProvider>
  );
}

export default FCAReferenceForm;
