import {
  BeneficiaryFormData,
  BeneficiaryFormDataWithId,
  findExistingBeneficiary,
  toApplicationBeneficiary,
  toBeneficiaryNomination,
} from './beneficiary-helpers';
import http from './http';
import { BeneficiaryDto } from './models/beneficiary-dto';
import { BeneficiaryNominationDto } from './models/beneficiary-nomination-dto';

/**
 * Add new beneficiary
 */
async function addBeneficiary(applicationId: string, beneficiary: BeneficiaryDto): Promise<BeneficiaryDto> {
  return http.post(`/api/v1/bff/application/${applicationId}/beneficiaries`, beneficiary);
}

/**
 * Delete beneficiary
 */
async function deleteBeneficiary(applicationId: string, beneficiaryId: string): Promise<unknown> {
  return http.delete(`/api/v1/bff/application/${applicationId}/beneficiaries/${beneficiaryId}`);
}

/**
 * Update beneficiary
 */
async function updateBeneficiary(applicationId: string, { id, ...beneficiary }: BeneficiaryDto): Promise<BeneficiaryDto> {
  return http.put(`/api/v1/bff/application/${applicationId}/beneficiaries/${id}`, beneficiary);
}

/**
 * Update product beneficiary
 */
async function updateBeneficiaryNomination(
  applicationId: string,
  productId: string,
  { beneficiaryId, ...beneficiary }: BeneficiaryNominationDto,
): Promise<BeneficiaryNominationDto> {
  return http.put(`/api/v1/bff/application/${applicationId}/beneficiaries/${beneficiaryId}/nomination/${productId}`, beneficiary);
}

/**
 * Delete beneficiary from product
 */
async function deleteBeneficiaryNomination(applicationId: string, productId: string, beneficiaryId: string): Promise<unknown> {
  return http.delete(`/api/v1/bff/application/${applicationId}/beneficiaries/${beneficiaryId}/nomination/${productId}`);
}

/**
 * Add or update beneficiaries
 */
async function processBeneficiaries(
  applicationId: string,
  formBeneficiaries: BeneficiaryFormData[],
  beneficiaries: BeneficiaryDto[],
): Promise<BeneficiaryFormDataWithId[]> {
  return Promise.all(formBeneficiaries.map(async (beneficiary) => {
    const existingBeneficiary = findExistingBeneficiary(beneficiary, beneficiaries);
    if (existingBeneficiary) {
      return { id: existingBeneficiary.id, ...beneficiary };
    }
    const updatedBeneficiary = await addBeneficiary(applicationId, toApplicationBeneficiary(beneficiary));
    return { id: updatedBeneficiary.id, ...beneficiary };
  }));
}

/**
 * Add or update a products beneficiaries
 */
async function processBeneficiaryNominations(
  applicationId: string,
  productId: string,
  updatedBeneficiaries: BeneficiaryFormDataWithId[],
  currentBeneficiaries: BeneficiaryNominationDto[],
): Promise<BeneficiaryNominationDto[]> {
  await Promise.all(currentBeneficiaries.filter(
    (beneficiary) => !updatedBeneficiaries.some((updatedBeneficiary) => updatedBeneficiary.id === beneficiary.beneficiaryId),
  ).map((beneficiary) => deleteBeneficiaryNomination(applicationId, productId, beneficiary.beneficiaryId!)));

  return Promise.all(updatedBeneficiaries.map(
    async (beneficiary) => {
      const response = await updateBeneficiaryNomination(applicationId, productId, toBeneficiaryNomination(productId, beneficiary));
      return response;
    },
  ));
}

/**
 * Add or product beneficiaries based on an array of beneficiary ids
 */
async function deleteBeneficiaryNominations(applicationId: string, productId: string, beneficiaryIds: string[]): Promise<unknown> {
  return Promise.all(beneficiaryIds.map((id) => deleteBeneficiaryNomination(applicationId, productId, id)));
}

const beneficiaryApi = {
  addBeneficiary,
  deleteBeneficiary,
  deleteBeneficiaryNomination,
  deleteBeneficiaryNominations,
  updateBeneficiary,
  updateBeneficiaryNomination,
  processBeneficiaries,
  processBeneficiaryNominations,
};

export default beneficiaryApi;
