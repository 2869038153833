import React from 'react';
import { Box } from '@mui/material';
import { Container } from '../../../components/layout/layout.styles';
import AppBar from '../../../components/layout/app-bar/app-bar';

function RegistrationTitle() {
  return (
    <Container maxWidth={false} disableGutters>
      <AppBar
        logoSize="large"
        header={<Box sx={{ flex: 1 }} />}
      />
    </Container>
  );
}

export default RegistrationTitle;
