import _ from 'lodash';
import moment, { Moment } from 'moment';

export const clientDateFormat = 'DD/MM/YYYY';
export const clientTimeFormat = 'HH:mm';
export const clientDateTimeFormat = 'DD/MM/YYYY HH:mm';
export const serverDateFormat = 'YYYY-MM-DD';
export const serverDateTimeFormat = 'YYYY-MM-DD HH:mm:ss ZZ';

export function parseServerDate(dateStr: string | null | undefined): Moment | null {
  if (!dateStr) {
    return null;
  }
  const date = moment(dateStr, serverDateFormat);
  return date.isValid() ? date : null;
}

export function parseServerDateTime(dateStr: string | null | undefined): Moment | null {
  if (!dateStr) {
    return null;
  }
  const date = moment(dateStr, serverDateTimeFormat);
  return date.isValid() ? date : null;
}

export function formatServerDate(date: Moment | null | undefined): string | null {
  if (!date || !date.isValid() || date.year().toString().length !== 4) {
    return null;
  }
  return date.format(serverDateFormat);
}

export function formatServerDateTime(date: Moment | null | undefined): string | null {
  if (!date || !date.isValid() || date.year().toString().length !== 4) {
    return null;
  }
  return date.format(serverDateTimeFormat);
}

export function parseClientDate(dateStr: string | null | undefined): Moment | null {
  if (!dateStr) {
    return null;
  }
  const date = moment(dateStr, clientDateFormat);
  return date.isValid() ? date : null;
}

export function formatClientDate(date: Moment | null | undefined): string | null {
  if (!date || !date.isValid() || date.year().toString().length !== 4) {
    return null;
  }
  return date.format(clientDateFormat);
}

export function formatClientTime(date: Moment | null | undefined): string | null {
  if (!date || !date.isValid() || date.year().toString().length !== 4) {
    return null;
  }
  return date.format(clientTimeFormat);
}

export function asClientDate(serverDate: string | null | undefined): string | null {
  return formatClientDate(parseServerDate(serverDate));
}

export function asClientDateTime(serverDate: string | null | undefined): string | null {
  return parseServerDateTime(serverDate)?.format(clientDateTimeFormat) ?? null;
}

export function asCustomClientDate(serverDate: string | null | undefined, format: string = clientDateFormat): string | null {
  return parseServerDate(serverDate)?.format(format) ?? null;
}

export function asClientTime(serverDate: string | null | undefined): string | null {
  return formatClientTime(moment(serverDate));
}

export function asServerDate(date: string | null | undefined): string | null {
  return formatServerDate(parseServerDate(date));
}

export function asServerDateTime(date: string | null | undefined): string | null {
  return formatServerDateTime(parseServerDateTime(date));
}

export function asAge(dateOfBirthServerDate: string | null | undefined): number | null {
  return dateOfBirthServerDate ? moment().diff(dateOfBirthServerDate, 'years') : null;
}

export function todayDate(): Moment {
  return moment();
}

export function asStringOrNull(value: unknown) {
  if (value === null || value === undefined || value === '') {
    return null;
  }
  return `${value}`;
}

function currencyFormatter(options?: Partial<Intl.NumberFormatOptions>) {
  return new Intl.NumberFormat('en-UK', {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: 0,
    ...options,
  });
}

export const numberToMoney = (num: number, options?: Partial<Intl.NumberFormatOptions>): string => currencyFormatter(options).format(num);

export const stringToMoney = (str: string, options?: Partial<Intl.NumberFormatOptions>): string => currencyFormatter(options).format(parseFloat(str));

export type SystemOfMeasurement = 'metric' | 'imperial';

export const systemsOfMeasurement: SystemOfMeasurement[] = ['metric', 'imperial'];

export type ImperialLength = { feet: number | null, inches: number | null };

export function toCentimeters(meters: number | null | undefined): number | null {
  return meters != null ? meters * 100 : null;
}

export function toMeters(centimeters: number | null | undefined): number | null {
  return centimeters != null ? centimeters / 100 : null;
}

export function imperialToCentimeters({ feet, inches }: ImperialLength): number | null {
  if (feet == null && inches == null) {
    return null;
  }
  const totalInInches = (feet || 0) * 12 + (inches || 0);
  return totalInInches * 2.54;
}

export function imperialToMeters(imperial: ImperialLength): number | null {
  return toMeters(imperialToCentimeters(imperial));
}

export function centimetersToImperial(centimeters: number | null | undefined): ImperialLength {
  if (centimeters == null) {
    return { feet: null, inches: null };
  }
  const totalInInches = centimeters / 2.54;
  const feet = Math.floor(totalInInches / 12);
  const inches = totalInInches - feet * 12;
  return { feet, inches };
}

export function metersToImperial(meters: number | null | undefined): ImperialLength {
  return centimetersToImperial(toCentimeters(meters));
}

export type ImperialWeight = { stones: number | null, pounds: number | null };

export function kilogrammsToImperial(kgs: number | null | undefined): ImperialWeight {
  if (kgs == null) {
    return { stones: null, pounds: null };
  }
  const totalInPounds = kgs * 2.20462;
  const stones = Math.floor(totalInPounds / 14);
  const pounds = totalInPounds - stones * 14;
  return { stones, pounds };
}

export function imperialToKilogramms({ stones, pounds }: ImperialWeight): number | null {
  if (stones == null && pounds == null) {
    return null;
  }
  const totalInPounds = (stones || 0) * 14 + (pounds || 0);
  return totalInPounds / 2.20462;
}

export function byteToMB(value: number): number {
  return value / (1024 ** 2);
}

export function mbToByte(value: number): number {
  return value * (1024 ** 2);
}

const ordinalSuffixes = ['th', 'st', 'nd', 'rd'];
export const nthNumber = (n: number) => {
  const index = (n > 3 && n < 21) || n % 10 > 3 ? 0 : n % 10;
  return `${n}${ordinalSuffixes[index]}`;
};

const nameDelimiters = [' ', '\'', '-'];
const surnamePrefixes = ['Mc', 'Mac'];

function correctCaseName(name: string): string {
  return nameDelimiters.reduce((s, delimiter) => (
    s.split(delimiter).map(_.upperFirst).join(delimiter)), _.toLower(name));
}

export const correctCaseFirstName = correctCaseName;

export function correctCaseSurname(name: string): string {
  return surnamePrefixes.reduce(
    (s, prefix) => (s.startsWith(prefix)
      ? `${prefix}${_.upperFirst(s.substring(prefix.length))}`
      : s),
    correctCaseName(name),
  );
}

export function removeWhitespace(value: string): string {
  return value.replace(/ /g, '');
}

export function titleCase(str: string | null | undefined): string | null {
  if (!str) {
    return null;
  }
  return str.charAt(0).toUpperCase() + str.substring(1).toLowerCase();
}

export function roundToOneDecimal(num: number): string | number {
  const rounded = Math.round(num * 10) / 10;
  return Number.isInteger(rounded) ? rounded : rounded.toFixed(1);
}
