import React, { useState } from 'react';
import * as yup from 'yup';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { RadioOptionsField } from '../../../../components/form/fields';
import { BeneficiaryAlert } from './beneficiary-nomination.styles';
import { ProductDto } from '../../../../services/models/product-dto';
import { booleanToYesNo, yesNoToBoolean } from '../../../../components/form/form.utils';
import BeneficiariesPanel from '../beneficiaries-panel';
import BeneficiaryDrawer from '../beneficiary-drawer';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  patchBeneficiaryNominationNomination,
  selectApplication,
  selectBeneficiaryDetails,
  updateBeneficiaryNomination,
} from '../../../../features/application-slice';
import { getBeneficiaryDetailsByProduct } from '../../../../services/beneficiary-helpers';
import beneficiaryApi from '../../../../services/beneficiary-api';
import useBusyState from '../../../../hooks/use-busy-state';
import { toastError } from '../../../../components/toast';

const validationSchema = yup.object({
  addBeneficiary: yup.boolean().required('Required'),
});

export interface BeneficiaryNominationProps {
  product: ProductDto
}

function BeneficiaryNomination({ product }: BeneficiaryNominationProps) {
  const { t } = useTranslation();
  const [busy, withBusyState] = useBusyState();
  const application = useAppSelector(selectApplication);
  const beneficiaryDetails = getBeneficiaryDetailsByProduct(product.id, useAppSelector(selectBeneficiaryDetails));
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const formMethods = useForm({
    defaultValues: {
      addBeneficiary: booleanToYesNo(product.beneficiaryNomination),
    },
    resolver: yupResolver(validationSchema) as any,
  });
  const { setValue, resetField } = formMethods;
  const dispatch = useAppDispatch();

  const handleAddBeneficiary = async () => {
    setOpenDrawer(true);
  };

  const updateAddBeneficiaryField = (value: 'yes' | 'no' | null) => {
    resetField('addBeneficiary', { defaultValue: value });
    setValue('addBeneficiary', value);
  };

  const handleNomincateBeneficiaries = withBusyState(async (name: string, value: string | null) => {
    try {
      await dispatch(patchBeneficiaryNominationNomination(application.id, product.id, yesNoToBoolean(value)));
    } catch {
      toastError(t('components.beneficiaryNomination.addBeneficiaryError'));
      updateAddBeneficiaryField(yesNoToBoolean(value) ? 'no' : 'yes');
      return;
    }
    if (value === 'no') {
      const productBeneficiaries = await beneficiaryApi.processBeneficiaryNominations(application.id, product.id, [], beneficiaryDetails.nominations);
      dispatch(updateBeneficiaryNomination(product.id, productBeneficiaries));
    }
    updateAddBeneficiaryField(value as 'yes' | 'no' | null);
  });

  return (
    <FormProvider {...formMethods}>
      <Typography variant="h6" component="h3" gutterBottom>
        {t('components.beneficiaryNomination.nominatingBeneficiaries')}
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        {t('components.beneficiaryNomination.supplyingDetails')}
      </Typography>
      <Stack gap={3}>
        <RadioOptionsField
          label={t('components.beneficiaryNomination.addBeneficiary')}
          name="addBeneficiary"
          options={['yes', 'no']}
          onChangeCommitted={handleNomincateBeneficiaries}
          sx={{
            minWidth: 200,
          }}
          labelTranslationBasePath="common.yesNo"
          hideError
          disabled={busy}
        />
        {product.beneficiaryNomination === false && (
          <BeneficiaryAlert severity="info" sx={{ mt: 2 }} data-testid="beneficiary-warning">
            <Typography
              variant="body2"
              dangerouslySetInnerHTML={{ __html: t('components.beneficiaryNomination.noBeneficiaryWarning') }}
            />
          </BeneficiaryAlert>
        )}
        {product.beneficiaryNomination === true && (
          <BeneficiariesPanel onAddBeneficiary={handleAddBeneficiary} beneficiaryDetails={beneficiaryDetails} />
        )}
      </Stack>
      <BeneficiaryDrawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        applicationId={application.id}
        product={product}
        beneficiaryDetails={beneficiaryDetails}
        applicants={application.applicants}
      />
    </FormProvider>
  );
}

export default BeneficiaryNomination;
