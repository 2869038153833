import ReactGA from 'react-ga4';
import { UaEventOptions } from 'react-ga4/types/ga4';
import { UnderwriteMeEnquiryType } from './models/underwrite-me-enquiry-type';
import { googleAnalyticsTag } from '../exports';

export const initGA = () => {
  if (typeof googleAnalyticsTag === 'string' && googleAnalyticsTag.length > 0) {
    ReactGA.initialize(googleAnalyticsTag, { testMode: false });
  }
};

export const trackPage = (page: string) => {
  if (typeof googleAnalyticsTag === 'string' && googleAnalyticsTag.length > 0) {
    ReactGA.send({ hitType: 'pageview', page });
  }
};

export const trackEvent = (options: UaEventOptions) => {
  if (typeof googleAnalyticsTag === 'string' && googleAnalyticsTag.length > 0) {
    ReactGA.event({
      transport: 'xhr',
      ...options,
    });
  }
};

export const trackSearchRequest = (searchTerm: string) => {
  trackEvent({
    category: 'Applications',
    action: 'Search',
    label: searchTerm,
  });
};

export const trackConvertToApplication = (applicationId: string) => {
  trackEvent({
    category: 'Applications',
    action: 'ConvertToApplication',
    label: applicationId,
  });
};

export const trackNewApplication = (applicationId: string, enquiryType: UnderwriteMeEnquiryType) => {
  trackEvent({
    category: 'Applications',
    action: `New${enquiryType.toUpperCase()}Application`,
    label: applicationId,
  });
};

export const trackGetQuote = (applicationId: string) => {
  trackEvent({
    category: 'Applications',
    action: 'GetQuote',
    label: applicationId,
  });
};

export const trackGetDecision = (applicationId: string) => {
  trackEvent({
    category: 'Applications',
    action: 'GetDecision',
    label: applicationId,
  });
};

export const trackBuyCover = (applicationId: string) => {
  trackEvent({
    category: 'Applications',
    action: 'BuyCover',
    label: applicationId,
  });
};

export const trackStartCover = (applicationId: string) => {
  trackEvent({
    category: 'Applications',
    action: 'StartCover',
    label: applicationId,
  });
};

export const trackActivateCover = (applicationId: string) => {
  trackEvent({
    category: 'Applications',
    action: 'ActivateCover',
    label: applicationId,
  });
};

export const trackResourceDownload = (resourceName: string) => {
  trackEvent({
    category: 'Documents',
    action: 'Download resource',
    label: resourceName,
  });
};

const analytics = {
  initGA,
  trackEvent,
  trackPage,
  trackSearchRequest,
  trackConvertToApplication,
  trackNewApplication,
  trackGetQuote,
  trackGetDecision,
  trackBuyCover,
  trackStartCover,
  trackActivateCover,
  trackResourceDownload,
};

export default analytics;
