import React from 'react';
import {
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { BeneficiaryDto } from '../../../../services/models/beneficiary-dto';
import { BeneficiaryFormData, filterExistingBeneficiaries } from '../../../../services/beneficiary-helpers';
import { StyledBeneficiarySelect } from './beneficiary-form.styles';
import { asClientDate } from '../../../../utils/converters';

interface BeneficiarySelectProps {
  fields: Omit<BeneficiaryFormData, 'rowId'>;
  beneficiaries: BeneficiaryDto[];
  onChange: (beneficiary: BeneficiaryDto | null) => unknown;
}

function BeneficiarySelect({ fields, beneficiaries, onChange }: BeneficiarySelectProps) {
  const matchedBeneficiaries = filterExistingBeneficiaries(fields, beneficiaries);
  return (
    <Collapse in={matchedBeneficiaries.length > 0 && (!fields.percentage || !fields.relationship)} sx={{ width: '100%' }}>
      <StyledBeneficiarySelect>
        <Stack direction="column" alignItems="flex-start">
          <Typography variant="body2">Select existing beneficiaries:</Typography>
          <List>
            {matchedBeneficiaries.map((beneficiary) => (
              <ListItem disablePadding key={beneficiary.id}>
                <ListItemButton onClick={() => onChange(beneficiary)}>
                  <ListItemText primary={`${beneficiary.firstName} ${beneficiary.surname} (${asClientDate(beneficiary.dateOfBirth)})`} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Stack>
      </StyledBeneficiarySelect>
    </Collapse>
  );
}

export default BeneficiarySelect;
