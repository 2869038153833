import * as yup from 'yup';
import { v4 as uuidv4 } from 'uuid';
import { BeneficiaryNominationDto } from './models/beneficiary-nomination-dto';
import { BeneficiaryDto } from './models/beneficiary-dto';
import { asClientDate, asServerDate } from '../utils/converters';
import { BeneficiaryDetails } from './models/beneficiary-details';
import { ProductDto } from './models/product-dto';
import { ApplicantDto } from './models/applicant-dto';

export type BeneficiaryFormData = {
  rowId: string,
  id?: string | null,
  firstName?: string | null,
  surname?: string | null,
  dateOfBirth?: string | null,
  relationship?: string | null,
  percentage?: number | null,
};

export type BeneficiaryFormDataWithId = BeneficiaryFormData & {
  id?: string | null,
};

export const beneficiaryValidationSchema = yup.object({
  firstName: yup.string().required('Required'),
  surname: yup.string().required('Required'),
  dateOfBirth: yup.string().required('Required'),
  relationship: yup.string().required('Required'),
  otherRelationship: yup.string()
    .when('relationship', {
      is: 'Other',
      then: (schema) => schema.required('Required'),
      otherwise: (schema) => schema.notRequired(),
    }),
  percentage: yup.number().min(0).max(100).required('Required'),
});

export function getBeneficiaryDetailsByProduct(productId: string, beneficiaryDetails: BeneficiaryDetails | null | undefined): BeneficiaryDetails {
  if (beneficiaryDetails) {
    return {
      ...beneficiaryDetails,
      nominations: beneficiaryDetails?.nominations.filter((nomination) => nomination.productId === productId),
    };
  }
  return {
    beneficiaries: [],
    nominations: [],
  };
}

export function getBeneficiaryTotal(beneficiaries: BeneficiaryNominationDto[]): number {
  return beneficiaries.reduce((acc, curr) => {
    if (curr?.percentage) {
      return acc + curr.percentage;
    }
    return acc;
  }, 0);
}

export function isBeneficiariesValid(beneficiaries: BeneficiaryFormData[]): boolean {
  if (getBeneficiaryTotal(beneficiaries) > 100 || getBeneficiaryTotal(beneficiaries) <= 99.8) {
    return false;
  }
  try {
    return !beneficiaries.some((beneficiary) => !beneficiaryValidationSchema.validateSync(beneficiary, { abortEarly: true }));
  } catch {
    return false;
  }
}

export function toApplicationBeneficiary({
  rowId,
  percentage,
  relationship,
  ...beneficiary
}: BeneficiaryFormData): BeneficiaryDto {
  return { ...beneficiary, dateOfBirth: asServerDate(beneficiary.dateOfBirth) };
}

export function toBeneficiaryNomination(productId: string, { id, percentage, relationship }: BeneficiaryFormDataWithId): BeneficiaryNominationDto {
  return {
    beneficiaryId: id,
    productId,
    percentage,
    relationship,
  };
}

export function compareBeneficiaryProperty(str1: string | null | undefined, str2: string | null | undefined): boolean {
  if (str1 && str2) {
    return str1.toLowerCase() === str2.toLowerCase();
  }
  return false;
}

export function findExistingBeneficiary(
  formBeneficiary: BeneficiaryFormData,
  beneficiaries: BeneficiaryDto[],
): BeneficiaryDto | undefined {
  return beneficiaries.find(
    (beneficiary) => compareBeneficiaryProperty(beneficiary?.firstName, formBeneficiary?.firstName)
      && compareBeneficiaryProperty(beneficiary?.surname, formBeneficiary?.surname)
      && compareBeneficiaryProperty(beneficiary?.dateOfBirth, formBeneficiary?.dateOfBirth),
  );
}

export function isFormBeneficiaryMatched(formBeneficiary: Pick<BeneficiaryFormData, 'firstName' | 'surname' | 'dateOfBirth'>, beneficiary: BeneficiaryDto): boolean {
  return (compareBeneficiaryProperty(beneficiary?.firstName, formBeneficiary?.firstName)
  && compareBeneficiaryProperty(beneficiary?.surname, formBeneficiary?.surname)
  && compareBeneficiaryProperty(beneficiary?.dateOfBirth, formBeneficiary?.dateOfBirth)
  );
}

export function isFormBeneficiaryPartiallyMatched(formBeneficiary: Pick<BeneficiaryFormData, 'firstName' | 'surname' | 'dateOfBirth'>, beneficiary: BeneficiaryDto): boolean {
  if (formBeneficiary?.firstName && formBeneficiary?.surname && formBeneficiary?.dateOfBirth) {
    return false;
  }
  return (compareBeneficiaryProperty(beneficiary?.firstName, formBeneficiary?.firstName)
  || compareBeneficiaryProperty(beneficiary?.surname, formBeneficiary?.surname)
  || compareBeneficiaryProperty(beneficiary?.dateOfBirth, formBeneficiary?.dateOfBirth))
  && !(isFormBeneficiaryMatched(formBeneficiary, beneficiary));
}

export function isBeneficiaryApplicant(beneficiaryForm: BeneficiaryFormData | BeneficiaryDto, applicant: ApplicantDto): boolean {
  return compareBeneficiaryProperty(beneficiaryForm.firstName, applicant.firstName)
  && compareBeneficiaryProperty(beneficiaryForm.surname, applicant.lastName)
  && compareBeneficiaryProperty(asClientDate(beneficiaryForm.dateOfBirth), asClientDate(applicant.dateOfBirth));
}

export function filterExistingBeneficiaries(formBeneficiary: Pick<BeneficiaryFormData, 'firstName' | 'surname' | 'dateOfBirth'>, beneficiaries: BeneficiaryDto[]): BeneficiaryDto[] {
  return beneficiaries.filter(
    (beneficiary) => isFormBeneficiaryPartiallyMatched(formBeneficiary, beneficiary),
  );
}

export function filterFormBeneficiaries(
  formBeneficiaries: BeneficiaryFormData[],
  beneficiaries: BeneficiaryDto[],
  applicants?: ApplicantDto[],
): BeneficiaryDto[] {
  return beneficiaries.filter(
    (beneficiary) => !formBeneficiaries.some(
      (formBeneficiary) => isFormBeneficiaryMatched(formBeneficiary, beneficiary),
    )
      && !applicants?.some((applicant) => isBeneficiaryApplicant(beneficiary, applicant)),
  );
}

export function mergeApplicationBeneficiaries(current: BeneficiaryDto[], updated: BeneficiaryDto[]): BeneficiaryDto[] {
  const mergedArray = [...current, ...updated];
  const uniqueBeneficiaries = new Map<string, BeneficiaryDto>();

  mergedArray.forEach((obj) => {
    if (obj.id) {
      uniqueBeneficiaries.set(obj.id, obj);
    }
  });

  return Array.from(uniqueBeneficiaries.values());
}

export function generateFormRowId(): string {
  return uuidv4();
}

export function toBeneficiaryFormData(beneficiaries: BeneficiaryNominationDto[], applicationBeneficiaries: BeneficiaryDto[]): BeneficiaryFormData[] {
  return beneficiaries.map(({ beneficiaryId, ...beneficiary }) => {
    const applicantBeneficiary = applicationBeneficiaries.find((b) => b.id === beneficiaryId);
    return {
      ...beneficiary,
      ...applicantBeneficiary,
      rowId: generateFormRowId(),
    };
  });
}

export function getProductApplicant(product: ProductDto, applicants: ApplicantDto[]): ApplicantDto | undefined {
  return applicants.find((applicant) => product.applicantIds?.includes(applicant.id));
}

export function getProductOtherApplicant(product: ProductDto, applicants: ApplicantDto[]): ApplicantDto | undefined {
  return applicants.find((applicant) => !product.applicantIds?.includes(applicant.id));
}

export function beneficiariesHasApplicant(beneficiaryForms: BeneficiaryFormData[], beneficiaries: BeneficiaryDto[], applicant: ApplicantDto): boolean {
  return beneficiaryForms.some((beneficiaryForm) => isBeneficiaryApplicant(beneficiaryForm, applicant)
    || beneficiaries.some((beneficiary) => isBeneficiaryApplicant(beneficiary, applicant)));
}
