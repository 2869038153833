import {
  Table,
  styled,
} from '@mui/material';

export const BeneficiariesTable = styled(Table)(({ theme }) => ({
  '& thead > tr > th': {
    borderBottom: 'none',
    paddingBottom: theme.spacing(1),
    ...theme.typography.body2,
  },
  '& tbody > tr > td': {
    borderBottom: 'none',
    fontFamily: theme.typography.h5.fontFamily,
    '&:first-of-type': {
      paddingLeft: 0,
    },
  },
}));

export default {};
