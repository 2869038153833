import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { isNil } from 'lodash';
import { useFormContext } from 'react-hook-form';
import {
  CheckboxField,
  SelectField,
} from '../../../../components/form/fields';
import { FormContainerStyled } from './income-protection-form-styles';
import { ProductEditability, ProductFormProps, getDisallowedPremiumStylesFor } from '../../../../services/product-helpers';
import { PremiumStyle } from '../../../../services/models/premium-style';
import { DeferredPeriodInWeeks } from '../../../../services/models/deferred-period-in-weeks';
import { ClaimPeriod } from '../../../../services/models/claim-period';
import { CoverType } from '../../../../services/models/cover-type';
import TermOrToAge from '../term-or-toage/term-or-toage';
import CoverAmountField from '../cover-amount-field';
import { stringToNumericEnum } from '../../../../components/form/form.utils';
import { InputInfoText } from '../../../../components/info';
import DeferredPeriodField from './deferred-period-field';

function IncomeProtectionForm({
  onChangeCommitted,
  decisionCoverAmount = undefined,
  restrictions = undefined,
  editable = ProductEditability.Editable,
}: ProductFormProps) {
  const { t } = useTranslation();
  const { watch } = useFormContext();
  const deferredPeriod = stringToNumericEnum<DeferredPeriodInWeeks>(watch('deferredPeriod'));

  return (
    <FormContainerStyled>
      <Stack direction="row" gap={2} flexWrap="wrap">
        <CoverAmountField
          label={t('components.incomeProtectionForm.coverAmount')}
          onChangeCommitted={onChangeCommitted}
          decisionCoverAmount={decisionCoverAmount}
          readOnly={editable === ProductEditability.ReadOnly || !isNil(restrictions?.coverAmount)}
        />
        <SelectField
          name="premiumStyle"
          label={t('components.incomeProtectionForm.premiumOption')}
          options={Object.values(PremiumStyle)}
          disabledOptions={getDisallowedPremiumStylesFor(deferredPeriod)}
          sx={{
            minWidth: 216,
          }}
          onChangeCommitted={onChangeCommitted}
          labelTranslationBasePath="components.incomeProtectionForm.premiumStyleOptions"
          hideError
          readOnly={editable !== ProductEditability.Editable}
          infoText={<InputInfoText {...t('components.incomeProtectionForm.infoText.premiumOption', { returnObjects: true })} />}
        />
        <CheckboxField
          label={t('components.incomeProtectionForm.coverType')}
          valueLabel={t('common.yes')}
          name="coverType"
          checkedValue={CoverType.Increasing}
          uncheckedValue={CoverType.Level}
          onChangeCommitted={onChangeCommitted}
          hideError
          disabled={editable !== ProductEditability.Editable || !isNil(restrictions?.coverType)}
          infoText={<InputInfoText {...t('components.incomeProtectionForm.infoText.coverType', { returnObjects: true })} />}
        />
        <TermOrToAge
          onChangeCommitted={onChangeCommitted}
          readOnly={editable !== ProductEditability.Editable || !isNil(restrictions?.term) || !isNil(restrictions?.toAge)}
        />
      </Stack>
      <Stack direction="row" gap={2} sx={{ marginTop: 2 }} flexWrap="wrap">
        <SelectField
          name="claimPeriod"
          label={t('components.incomeProtectionForm.claimPeriod')}
          options={Object.values(ClaimPeriod)}
          sx={{
            minWidth: 180,
          }}
          onChangeCommitted={onChangeCommitted}
          labelTranslationBasePath="components.incomeProtectionForm.claimPeriodOptions"
          hideError
          readOnly={editable !== ProductEditability.Editable || !isNil(restrictions?.claimPeriod)}
        />
        <DeferredPeriodField
          onChangeCommitted={onChangeCommitted}
          deferredPeriod={deferredPeriod}
          restrictions={restrictions}
          readOnly={editable !== ProductEditability.Editable}
        />
      </Stack>
    </FormContainerStyled>
  );
}

export default IncomeProtectionForm;
