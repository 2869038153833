export const adviserCognito = {
  cognitoUserPoolId: 'eu-west-1_49o4d41Sk',
  cognitoIdentityPoolId: 'eu-west-1:e5b99af9-d110-4eeb-9227-c55e55d92de2',
  cognitoAppClientId: '7a2e4n4l15dei19k1bo1uu0cb9',
  cognitoUserPoolDomain: 'theexeter-adviser-dev.auth.eu-west-1.amazoncognito.com',
  cognitoRegion: 'eu-west-1',
  cognitoScope: 'aws.cognito.signin.user.admin+email+openid+phone+profile',
};

export const collegueCognito = {
  cognitoUserPoolId: 'eu-west-1_YiuEPRjOh',
  cognitoIdentityPoolId: 'eu-west-1:1ee414f1-7cbb-4ec5-b5fc-3e3a95c5662c',
  cognitoAppClientId: '57bnqh4404fft79ce86r6396c5',
  cognitoUserPoolDomain: 'theexeter-colleague-dev.auth.eu-west-1.amazoncognito.com',
  cognitoRegion: 'eu-west-1',
  cognitoScope: 'aws.cognito.signin.user.admin+email+openid+phone+profile',
};

export const registrationEnabled = true;
export const googleAnalyticsTag = '';
export const googleGtag = 'GTM-TFDQPZT4';
export const inactivityTimeout = '30'
export const authCookieExpiry = '1'

export default {};
