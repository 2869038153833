import React from 'react';
import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Container } from '../../../../components/layout/layout.styles';
import AppBar from '../../../../components/layout/app-bar/app-bar';
import { HeaderStack } from '../../../application/components/application-layout/application-layout.styles';
import StatusIndicator from '../../../application/components/status-indicator';
import { ProgressStatus } from '../../../../services/models/progress-status';
import { RegistrationStep } from '../../../../services/registration-helpers';

function getActiveStepIndex(activeStep: RegistrationStep): number {
  return Object.values(RegistrationStep).findIndex((step) => activeStep === step);
}

interface FirmRegistrationHeaderProps {
  activeStep: RegistrationStep | null
  onExit: () => unknown
}

function FirmRegistrationHeader({ activeStep, onExit }: FirmRegistrationHeaderProps) {
  const { t } = useTranslation();
  const activeStepIndex = activeStep ? getActiveStepIndex(activeStep) : 0;

  const steps = Object.values(RegistrationStep).map((step, index) => (
    {
      label: t(`components.firmRegistrationHeader.statusIndicator.${step}`),
      status: index < activeStepIndex ? ProgressStatus.Complete : ProgressStatus.NotStarted,
    }
  ));

  return (
    <Container maxWidth={false} disableGutters>
      <AppBar
        logoSize="small"
        header={(
          <HeaderStack inline gap={2} justifyContent="space-between" sx={{ flex: 1, marginX: 3 }}>
            <Typography variant="h2">{t('components.firmRegistrationHeader.title')}</Typography>
            <StatusIndicator
              steps={steps}
              activeStep={activeStepIndex}
              sx={{ mr: 'auto', ml: 'auto' }}
            />
            <Button
              variant="outlined"
              color="secondary"
              onClick={onExit}
            >
              {t('common.exit')}
            </Button>
          </HeaderStack>
        )}
      />
    </Container>
  );
}

export default FirmRegistrationHeader;
