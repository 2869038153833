import React, { useState } from 'react';
import {
  Button,
  ButtonGroup,
  Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormFieldContainer, InputStatus } from '../../../../components/form';
import { PostcodeField, TextField } from '../../../../components/form/fields';
import { PostcodeSearch } from '../../../../components/address-search';
import { AddressDto } from '../../../../services/models/address-dto';
import applicationApi from '../../../../services/application-api';
import { toastError } from '../../../../components/toast';
import { formatLoqateAddress } from '../../../../services/application-helpers';

interface AddressEntryProps {
  addressName: string;
  onAddressChange: (addressName: string, address: AddressDto) => unknown;
}

function FirmAddressEntry({ addressName, onAddressChange }: AddressEntryProps) {
  const { t } = useTranslation();
  const [showAddressFields, setShowAddressFields] = useState<boolean>(false);

  const handleShowAddressSearch = () => {
    setShowAddressFields(false);
  };

  const handleAddressSelect = async (newAddress: AddressDto | null) => {
    try {
      if (newAddress && newAddress.id) {
        const foundAddress = await applicationApi.searchAddressById(newAddress.id);
        onAddressChange(addressName, formatLoqateAddress(foundAddress));
        setShowAddressFields(true);
      }
    } catch (e) {
      toastError(t('components.firmRegistration.firmDetailsForm.addressSelectError'));
    }
  };

  return (
    <>
      <Stack gap={2} sx={{ mt: 2, mb: 2 }}>
        <ButtonGroup variant="outlined" color="secondary">
          <Button variant={!showAddressFields ? 'contained' : 'outlined'} onClick={handleShowAddressSearch}>Lookup address</Button>
          <Button variant={showAddressFields ? 'contained' : 'outlined'} onClick={() => setShowAddressFields(true)}>Enter manually</Button>
        </ButtonGroup>
      </Stack>
      {!showAddressFields && (
        <PostcodeSearch
          defaultValue=""
          onChange={handleAddressSelect}
        />
      )}
      {showAddressFields && (
        <>
          <FormFieldContainer hideDivider rowEnd={<InputStatus />}>
            <TextField
              sublabel={t('components.firmRegistration.firmDetailsForm.address1')}
              name={`${addressName}_address1`}
              InputProps={{
                inputProps: {
                  maxLength: 60,
                  role: 'presentation',
                  autoComplete: 'off',
                },
              }}
            />
          </FormFieldContainer>
          <FormFieldContainer hideDivider rowEnd={<InputStatus />}>
            <TextField
              sublabel={t('components.firmRegistration.firmDetailsForm.address2')}
              name={`${addressName}_address2`}
              InputProps={{
                inputProps: { maxLength: 60, autoComplete: 'off', role: 'presentation' },
              }}
            />
          </FormFieldContainer>
          <FormFieldContainer hideDivider rowEnd={<InputStatus />}>
            <Stack direction="row" gap={3} className="MuiFormControl-root" alignItems="flex-start">
              <TextField
                sublabel={t('components.firmRegistration.firmDetailsForm.cityName')}
                name={`${addressName}_cityName`}
                FormControlProps={{ sx: { flex: '0 0 50%' } }}
                InputProps={{
                  inputProps: { maxLength: 50, autoComplete: 'off', role: 'presentation' },
                }}
              />
              <PostcodeField
                sublabel={t('components.firmRegistration.firmDetailsForm.postcode')}
                name={`${addressName}_postcode`}
                FormControlProps={{ sx: { flex: '0 0 50%' } }}
                inputProps={{
                  maxLength: 10, autoComplete: 'off', role: 'presentation',
                }}
              />
            </Stack>
          </FormFieldContainer>
        </>
      )}
    </>
  );
}

export default FirmAddressEntry;
