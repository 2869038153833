import {
  Alert,
  styled,
} from '@mui/material';

export const BeneficiaryAlert = styled(Alert)(({ theme }) => ({
  ...theme.typography.body2,
  alignItems: 'center',
  backgroundColor: theme.palette.common.white,
  border: `1px solid ${theme.palette.common.secondary200}`,
  maxWidth: 740,
  '& .MuiAlert-icon': {
    alignSelf: 'flex-start',
    marginTop: 4,
  },
  '& .MuiAlert-message, .MuiAlert-icon': {
    color: theme.palette.text.primary,
  },
}));

export default {};
