import React from 'react';
import {
  Box,
  Button,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useTranslation } from 'react-i18next';
import { RegistrationCard } from './registration-panel.styles';

type SectionItem = {
  title: string
  description: string[]
  requirements: string[]
};

interface RegistrationPanelProps {
  labelTranslationBasePath: string;
  onRegister: () => void;
}

function RegistrationPanel({ labelTranslationBasePath, onRegister }: RegistrationPanelProps) {
  const { t } = useTranslation();
  return (
    <RegistrationCard>
      <CardHeader
        title={t(`${labelTranslationBasePath}.title`)}
        titleTypographyProps={{ variant: 'h3' }}
        subheader={t(`${labelTranslationBasePath}.subTitle`)}
      />
      <CardContent>
        {t<string, SectionItem[]>(`${labelTranslationBasePath}.sections`, { returnObjects: true }).map(({ title, description, requirements }) => (
          <Box key={`${title}${description[0]}`}>
            {title && <Typography variant="h4" gutterBottom>{title}</Typography>}
            {description.map((line) => <Typography key={line} variant="body2" gutterBottom>{line}</Typography>)}
            <Typography variant="h5">{t('components.registration.youWillNeed')}</Typography>
            <ul>
              {requirements.map((requirement) => (
                <li key={requirement}>{requirement}</li>
              ))}
            </ul>
          </Box>
        ))}
      </CardContent>
      <CardActions>
        <Button
          size="large"
          variant="contained"
          color="primary"
          onClick={onRegister}
          endIcon={<ArrowForwardIcon />}
        >
          {t(`${labelTranslationBasePath}.registerButton`)}
        </Button>
      </CardActions>
    </RegistrationCard>
  );
}

export default RegistrationPanel;
