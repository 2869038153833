import {
  Box,
  Table,
  styled,
} from '@mui/material';

export const BeneficiaryTable = styled(Table)(({ theme }) => ({
  '& > thead > tr > th': {
    borderBottom: `1px solid ${theme.palette.common.secondary200}`,
    paddingBottom: theme.spacing(1),
    '&:first-of-type': {
      paddingLeft: 0,
    },
  },
  '& > tbody > tr > td': {
    border: 'none',
    borderBottom: `1px solid ${theme.palette.common.secondary200}`,
    '&:first-of-type': {
      paddingLeft: 0,
    },
  },
}));

export const StyledBeneficiarySelect = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.common.secondary200}`,
  borderRadius: 4,
  padding: theme.spacing(1),
  paddingBottom: 0,
  width: '100%',
  '& p': {
    fontFamily: theme.typography.h3.fontFamily,
  },
  '& ul': {
    width: '100%',
  },
}));

export default {};
