import React from 'react';
import { Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import RegistrationPanel from './components/registration-panel';
import { RegistrationContainer } from './registration.styles';
import Layout from '../../components/layout/layout';
import RegistrationTitle from './components/registration-title';
import { FirmRegistrationPage } from '../pages';

function Registration() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Layout header={<RegistrationTitle />}>
      <RegistrationContainer maxWidth={false}>
        <Stack>
          <Typography variant="h1" align="center">{t('components.registration.title')}</Typography>
          <Stack direction="row" gap={4} alignItems="flex-start" justifyContent="center">
            <RegistrationPanel labelTranslationBasePath="components.registration.firmRegistrationPanel" onRegister={() => navigate(FirmRegistrationPage.path)} />
            <RegistrationPanel labelTranslationBasePath="components.registration.adviserRegistrationPanel" onRegister={() => {}} />
          </Stack>
        </Stack>
      </RegistrationContainer>
    </Layout>
  );
}

export default Registration;
