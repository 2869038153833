import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../../components/layout/layout';
import FirmRegistrationHeader from './components/firm-registration-header';
import { RegistrationPage } from '../pages';
import FCAReferenceForm from './components/fca-reference-form';
import { FirmRegistrationContainer } from './firm-registration.styles';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  init,
  proceed,
  selectRegistationState,
  selectView,
  updateFirmRegistration,
} from '../../features/registration-slice';
import { firmDetailsView, firmReferenceView, View } from '../view';
import {
  FirmRegistrationDetails,
  getNextRegistrationStep,
  getPreviousRegistrationStep,
  getRegistrationStepFromContent,
  RegistrationFormProps,
  RegistrationStep,
} from '../../services/registration-helpers';
import FirmDetailsForm from './components/firm-details-form';
import RegistrationExitDialog from './components/registration-exit-dialog';

export function getRegistrationView(step: RegistrationStep) {
  switch (step) {
    case RegistrationStep.FCAREFERENCE: return firmReferenceView;
    case RegistrationStep.FIRMDETAILS: return firmDetailsView;
    default: return firmReferenceView;
  }
}

function getRegistrationContent(view: View, props: RegistrationFormProps) {
  switch (view.mainContent) {
    case RegistrationStep.FCAREFERENCE: return <FCAReferenceForm {...props} />;
    case RegistrationStep.FIRMDETAILS: return <FirmDetailsForm {...props} />;
    default: return null;
  }
}

function FirmRegistration() {
  const [openExitDialog, setOpenExitDialog] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const state = useAppSelector(selectRegistationState);
  const view = useAppSelector(selectView);
  const registrationStep = getRegistrationStepFromContent(view.mainContent);

  const handlePrevious = () => {
    if (registrationStep) {
      if (registrationStep === RegistrationStep.FCAREFERENCE) {
        navigate(RegistrationPage.path);
      } else {
        dispatch(proceed({ target: getRegistrationView(getPreviousRegistrationStep(registrationStep)) }));
      }
    }
  };

  const handleNext = (values: Partial<FirmRegistrationDetails>) => {
    if (registrationStep) {
      dispatch(updateFirmRegistration(values));
      dispatch(proceed({ target: getRegistrationView(getNextRegistrationStep(registrationStep)) }));
    }
  };

  const handleExitRegistration = () => {
    setOpenExitDialog(false);
    navigate(RegistrationPage.path);
  };

  useEffect(() => {
    dispatch(init());
  }, []);

  if (state.status !== 'available') {
    return null;
  }

  return (
    <Layout
      header={<FirmRegistrationHeader activeStep={registrationStep} onExit={() => setOpenExitDialog(true)} />}
    >
      <FirmRegistrationContainer>
        {getRegistrationContent(view, {
          registrationDetails: state.firmRegistration,
          onNext: handleNext,
          onPrevious: handlePrevious,
        })}
      </FirmRegistrationContainer>
      <RegistrationExitDialog open={openExitDialog} onCancel={() => setOpenExitDialog(false)} onConfirm={handleExitRegistration} />
    </Layout>
  );
}

export default FirmRegistration;
