import React from 'react';
import {
  Stack,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { useTranslation } from 'react-i18next';
import { toBeneficiaryFormData } from '../../../../services/beneficiary-helpers';
import { asClientDate } from '../../../../utils/converters';
import { BeneficiaryDetails } from '../../../../services/models/beneficiary-details';
import { BeneficiariesTable } from './beneficiaries-list.styles';

interface BeneficiariesListProp {
  beneficiaryDetails: BeneficiaryDetails
}

function BeneficiariesList({ beneficiaryDetails }: BeneficiariesListProp) {
  const { t } = useTranslation();
  const outputBeneficiaries = toBeneficiaryFormData(beneficiaryDetails.nominations, beneficiaryDetails.beneficiaries);
  return (
    <BeneficiariesTable size="small">
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell>{t('components.beneficiariesList.relationship')}</TableCell>
          <TableCell>{t('components.beneficiariesList.dateOfBirth')}</TableCell>
          <TableCell align="right" width={170}>{t('components.beneficiariesList.percentage')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {outputBeneficiaries.map((beneficiary) => (
          <TableRow key={beneficiary.rowId}>
            <TableCell>
              <Stack direction="row" gap={1} alignItems="center">
                <AccountCircleOutlinedIcon fontSize="small" color="primary" />
                {`${beneficiary.firstName} ${beneficiary.surname}`}
              </Stack>
            </TableCell>
            <TableCell>
              {beneficiary.relationship}
            </TableCell>
            <TableCell>
              {asClientDate(beneficiary.dateOfBirth)}
            </TableCell>
            <TableCell align="right">
              {`${beneficiary.percentage}%`}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </BeneficiariesTable>
  );
}

export default BeneficiariesList;
