import React, { useState } from 'react';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { isNil } from 'lodash';
import { useFormContext } from 'react-hook-form';
import { SelectField } from '../../../../components/form/fields';
import { DeferredPeriodInWeeks } from '../../../../services/models/deferred-period-in-weeks';
import { getDisallowedDeferredPeriodsFor } from '../../../../services/product-helpers';
import { ProductRestrictions } from '../../../../services/models/product-restrictions';
import { InputInfoText } from '../../../../components/info';
import { useAppSelector } from '../../../../store/hooks';
import { selectApplicationLastReferredDate, selectApplicationStatus } from '../../../../features/application-slice';
import { ApplicationStatus } from '../../../../services/models/application-status';

export interface DeferredPeriodFieldProps {
  onChangeCommitted: (name: string, value: unknown) => unknown;
  deferredPeriod?: DeferredPeriodInWeeks | null;
  restrictions?: ProductRestrictions | null;
  readOnly?: boolean;
}

function DeferredPeriodField({
  onChangeCommitted,
  deferredPeriod = undefined,
  restrictions = undefined,
  readOnly = undefined,
}: DeferredPeriodFieldProps) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [confirmingChange, setConfirmingChange] = useState(false);
  const applicationStatus = useAppSelector(selectApplicationStatus);
  const lastReferredDate = useAppSelector(selectApplicationLastReferredDate);
  const { watch } = useFormContext();
  const premiumStyle = watch('premiumStyle');

  const handleOpen = () => {
    if (applicationStatus !== ApplicationStatus.Decision && !(lastReferredDate && deferredPeriod)) {
      setOpen(true);
    } else {
      setConfirmingChange(true);
    }
  };

  const handleConfirm = () => {
    setOpen(true);
    setConfirmingChange(false);
  };

  const handleCancel = () => {
    setConfirmingChange(false);
  };

  return (
    <>
      <SelectField
        open={open}
        onOpen={handleOpen}
        onClose={() => setOpen(false)}
        name="deferredPeriod"
        label={t('components.deferredPeriodField.label')}
        options={Object.values(DeferredPeriodInWeeks).map((dp) => dp.toString())}
        disabledOptions={getDisallowedDeferredPeriodsFor(premiumStyle).map((dp) => dp.toString())}
        labelTranslationBasePath="common.deferredPeriod"
        onChangeCommitted={onChangeCommitted}
        hideError
        readOnly={readOnly || !isNil(restrictions?.deferredPeriod)}
        infoText={<InputInfoText intro={t('components.deferredPeriodField.infoText')} />}
      />
      <Dialog
        open={confirmingChange}
        onClose={handleCancel}
        aria-labelledby="deferred-period-prompt"
        aria-describedby="deferred-period-description"
      >
        <DialogTitle id="deferred-period-prompt">
          <Alert severity="warning">
            <Typography variant="h5" sx={(theme) => ({ color: theme.palette.common.greyDark })}>
              {t('components.deferredPeriodField.changeWarning')}
            </Typography>
          </Alert>
        </DialogTitle>
        <DialogActions sx={{
          p: 2,
          pl: 3,
          pr: 3,
          justifyContent: 'space-between',
        }}
        >
          <Button onClick={handleCancel} color="secondary">
            {t('common.cancel')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleConfirm}
            autoFocus
          >
            {t('common.confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DeferredPeriodField;
