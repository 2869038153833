import React from 'react';
import { Typography } from '@mui/material';
import { TooltipContent } from './info.styles';

interface InputInfoTextProps {
  intro: string;
  items?: string[];
}

function InputInfoText({ intro, items = undefined }: InputInfoTextProps) {
  return (
    <TooltipContent>
      {intro && (
        <Typography variant="caption" component="p" gutterBottom={!!items}>{intro}</Typography>
      )}
      {items && (
        <ul>
          {items.map((item) => (
            <li key={item}>{item}</li>
          ))}
        </ul>
      )}
    </TooltipContent>
  );
}

export default InputInfoText;
