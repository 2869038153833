import React from 'react';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { StyledDialog } from './registration-exit-dialog.styles';

interface RegistrationExitDialogProps extends DialogProps {
  onConfirm: () => unknown;
  onCancel: () => unknown;
}

function RegistrationExitDialog({ open, onConfirm, onCancel }: RegistrationExitDialogProps) {
  const { t } = useTranslation();

  return (
    <StyledDialog
      open={open}
      data-testid="exit-dialog"
    >
      <DialogTitle variant="h2">
        {t('components.firmRegistration.registrationExitDialog.title')}
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" gutterBottom>
          {t('components.firmRegistration.registrationExitDialog.description')}
        </Typography>
        <Typography variant="body1">
          {t('components.firmRegistration.registrationExitDialog.wishToExit')}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ paddingY: 2, paddingX: 3, justifyContent: 'space-between' }}>
        <Button variant="contained" color="primary" onClick={onConfirm}>
          {t('components.firmRegistration.registrationExitDialog.exitRegistration')}
        </Button>
        <Button variant="outlined" color="secondary" onClick={onCancel}>
          {t('common.cancel')}
        </Button>
      </DialogActions>
    </StyledDialog>
  );
}

export default RegistrationExitDialog;
