import {
  Box,
  styled,
} from '@mui/material';
import { FormFieldContainer } from '../../../../components/form';

export const FormInfoBar = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  minHeight: 68,
  backgroundColor: theme.palette.common.primary200,
  display: 'flex',
  alignItems: 'center',
  paddingLeft: theme.spacing(6),
  paddingRight: theme.spacing(6),
}));

export const AdditionalNeedsFormFieldContainer = styled(FormFieldContainer)(({ theme }) => ({
  '& .MuiFormHelperText-root': {
    lineHeight: 1.5,
    marginBottom: theme.spacing(2),
  },
}));

export default {};
