import {
  Table,
  styled,
} from '@mui/material';

export const BeneficiariesReviewTable = styled(Table)(() => ({
  '& td': {
    borderBottom: 'none',
    '&:first-of-type': {
      paddingLeft: 0,
    },
    '&:last-of-type': {
      paddingRight: 0,
    },
  },
}));

export default {};
