import {
  Box,
  Card,
  darken,
  keyframes,
  styled,
  Theme,
} from '@mui/material';
import { shouldForwardProp } from '../../../../components/types';

export const BeneficiariesCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  border: 'none',
  borderRadius: 0,
  boxShadow: '0px 0px 10px 3px rgba(0,0,0,0.05)',
  '& > .MuiCardContent-root': {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  '& hr': {
    marginLeft: theme.spacing(-4),
    marginRight: theme.spacing(-4),
    width: 'calc(100% + 64px)',
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: 700,
  },
}));

export type BeneficiariesTotalOutputProps = {
  showError?: boolean;
};

const pulseRed = (theme: Theme) => keyframes`
  0% {
    color: ${darken(theme.palette.error.main, 0.2)};
    transform: scale(1);
  }
  50% {
    color: ${theme.palette.error.main};
    transform: scale(1.1);
  }
  100% {
    color: ${darken(theme.palette.error.main, 0.2)};
    transform: scale(1);
  }
`;

export const BeneficiariesTotalOutput = styled(Box, {
  shouldForwardProp: (prop) => shouldForwardProp<BeneficiariesTotalOutputProps>(['showError'], prop),
})<BeneficiariesTotalOutputProps>(({ theme, showError }) => {
  if (showError) {
    return {
      animation: `${pulseRed(theme)} 1.5s infinite`,
      color: theme.palette.error.main,
      fontWeight: 900,
    };
  }
  return {
    animation: 'none',
    color: theme.palette.text.primary,
    fontWeight: 900,
  };
});

export default {};
