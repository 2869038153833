import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Box,
  Button,
  DrawerProps,
  Fade,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import {
  CancelDrawerButton,
  DrawerCard,
  DrawerCardActions,
  DrawerCardContent,
  DrawerCardHeader,
} from '../../../../components/drawer-card';
import BeneficiaryForm from './beneficiary-form';
import BeneficiariesTotal from '../beneficiaries-panel/beneficiaries-total';
import { Drawer } from '../../../application/components/application-layout/application-layout.styles';
import {
  beneficiariesHasApplicant,
  BeneficiaryFormData,
  isBeneficiaryApplicant,
  filterFormBeneficiaries,
  generateFormRowId,
  getBeneficiaryTotal,
  isBeneficiariesValid,
  mergeApplicationBeneficiaries,
  toApplicationBeneficiary,
  toBeneficiaryFormData,
  getProductOtherApplicant,
} from '../../../../services/beneficiary-helpers';
import { updateBeneficiaries, updateBeneficiaryNomination } from '../../../../features/application-slice';
import { useAppDispatch } from '../../../../store/hooks';
import { BeneficiaryDto } from '../../../../services/models/beneficiary-dto';
import useBusyState from '../../../../hooks/use-busy-state';
import beneficiaryApi from '../../../../services/beneficiary-api';
import { asClientDate } from '../../../../utils/converters';
import { BeneficiaryDetails } from '../../../../services/models/beneficiary-details';
import { ApplicantDto } from '../../../../services/models/applicant-dto';
import { ProductDto } from '../../../../services/models/product-dto';

function beneficiaryDisplay(firstName: string | null | undefined, surname: string | null | undefined, dateOfBirth: string | null | undefined) {
  return `${firstName} ${surname} (${asClientDate(dateOfBirth)})`;
}

export interface BeneficiaryDrawerProps extends DrawerProps {
  applicationId: string;
  product: ProductDto;
  beneficiaryDetails: BeneficiaryDetails
  applicants: ApplicantDto[],
  onClose: () => unknown;
}

function BeneficiaryDrawer({
  applicationId,
  product,
  beneficiaryDetails,
  applicants,
  onClose,
  ...props
}: BeneficiaryDrawerProps) {
  const { t } = useTranslation();
  const [busy, withBusyState] = useBusyState();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openAddMenu = Boolean(anchorEl);
  const dispatch = useAppDispatch();
  const { beneficiaries, nominations } = beneficiaryDetails;
  const otherApplicant = getProductOtherApplicant(product, applicants);

  const [beneficiaryForms, setBeneficiaryForms] = useState<BeneficiaryFormData[]>(
    beneficiaries && nominations.length > 0 ? toBeneficiaryFormData(nominations, beneficiaries) : [],
  );

  const total = useMemo(() => getBeneficiaryTotal(beneficiaryForms), [beneficiaryForms]);
  const valid = useMemo(() => isBeneficiariesValid(beneficiaryForms), [beneficiaryForms]);

  const handleOpenAddMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseAddMenu = () => {
    setAnchorEl(null);
  };

  const handleDelete = (beneficiary: BeneficiaryFormData) => {
    setBeneficiaryForms((state) => state.filter((item) => item.rowId !== beneficiary.rowId));
  };

  const handleAdd = (beneficiary?: BeneficiaryDto) => {
    setBeneficiaryForms((state) => [...state, { rowId: generateFormRowId(), ...beneficiary }]);
    handleCloseAddMenu();
  };

  const handleAddApplicant = ({
    firstName,
    lastName: surname,
    dateOfBirth,
  }: ApplicantDto) => {
    setBeneficiaryForms((state) => [...state, {
      rowId: generateFormRowId(),
      firstName,
      surname,
      dateOfBirth,
    }]);
    handleCloseAddMenu();
  };

  const handleUpdate = (rowId: string, values: BeneficiaryFormData) => {
    setBeneficiaryForms((state) => state.map((beneficiary) => {
      if (beneficiary.rowId === rowId) {
        return { ...beneficiary, ...values };
      }
      return beneficiary;
    }));
  };

  const handleSave = useCallback(withBusyState(async () => {
    const updatedBeneficiaries = await beneficiaryApi.processBeneficiaries(applicationId, beneficiaryForms, beneficiaries ?? []);
    const productBeneficiaries = await beneficiaryApi.processBeneficiaryNominations(applicationId, product.id, updatedBeneficiaries, nominations);
    dispatch(updateBeneficiaryNomination(product.id, productBeneficiaries));
    dispatch(updateBeneficiaries(
      mergeApplicationBeneficiaries(beneficiaries ?? [], updatedBeneficiaries.map((beneficiary) => toApplicationBeneficiary(beneficiary))),
    ));
    onClose();
  }), [beneficiaryForms, beneficiaries]);

  useEffect(() => {
    setBeneficiaryForms(beneficiaries && nominations.length > 0 ? toBeneficiaryFormData(nominations, beneficiaries) : []);
  }, [beneficiaries, nominations]);

  return (
    <Drawer
      anchor="bottom"
      {...props}
    >
      <DrawerCard>
        <DrawerCardHeader
          title={t('components.beneficiaryDrawer.title')}
          action={<CancelDrawerButton onClick={onClose} />}
        />
        <DrawerCardContent sx={{ paddingY: 2 }}>
          <Stack alignItems="flex-start" gap={2}>
            <Box sx={{ overflowX: 'scroll', width: '100%' }}>
              {beneficiaryForms.map((beneficiary, index) => (
                <BeneficiaryForm
                  key={beneficiary.rowId}
                  onDelete={() => handleDelete(beneficiary)}
                  onUpdate={(values) => handleUpdate(beneficiary.rowId, values)}
                  beneficiary={beneficiary}
                  applicationBeneficiaries={beneficiaries}
                  hideHeader={index > 0}
                  disabled={otherApplicant && isBeneficiaryApplicant(beneficiary, otherApplicant)}
                  sx={{ minWidth: 1200 }}
                />
              ))}
            </Box>
            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ width: '100%', pr: 11 }}>
              <Stack gap={2} direction="row" alignItems="center">
                <Button
                  variant="contained"
                  color="secondary"
                  endIcon={<AddIcon />}
                  disabled={beneficiaryForms.length >= 5}
                  onClick={handleOpenAddMenu}
                >
                  {t('common.add')}
                </Button>
                <Menu
                  id="add-menu"
                  MenuListProps={{
                    'aria-labelledby': 'add-button',
                  }}
                  anchorEl={anchorEl}
                  open={openAddMenu}
                  onClose={handleCloseAddMenu}
                  TransitionComponent={Fade}
                >
                  <MenuItem onClick={() => handleAdd()}>Add new</MenuItem>
                  {otherApplicant && !beneficiariesHasApplicant(beneficiaryForms, beneficiaries, otherApplicant)
                    && (
                      <MenuItem onClick={() => handleAddApplicant(otherApplicant)}>
                        {beneficiaryDisplay(otherApplicant.firstName, otherApplicant.lastName, otherApplicant.dateOfBirth)}
                      </MenuItem>
                    )}
                  {filterFormBeneficiaries(beneficiaryForms, beneficiaries, applicants).map((b) => (
                    <MenuItem key={b.id} onClick={() => handleAdd(b)}>{beneficiaryDisplay(b.firstName, b.surname, b.dateOfBirth)}</MenuItem>
                  ))}
                </Menu>
                <Typography variant="body1">{t('components.beneficiaryDrawer.max5beneficiaries')}</Typography>
              </Stack>
              <BeneficiariesTotal total={total} />
            </Stack>
          </Stack>
        </DrawerCardContent>
      </DrawerCard>
      <DrawerCardActions sx={{ justifyContent: 'flex-end', gap: 2 }}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={onClose}
        >
          {t('common.cancel')}
        </Button>
        <LoadingButton
          variant="contained"
          type="submit"
          color="primary"
          disabled={!valid}
          loading={busy}
          onClick={handleSave}
        >
          {t('common.save')}
        </LoadingButton>
      </DrawerCardActions>
    </Drawer>
  );
}

export default BeneficiaryDrawer;
